export const FACTORY_ADDRESS = '0xe0b8838e8d73ff1ca193e8cc2bc0ebf7cf86f620'.toLocaleLowerCase()

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  '0x665F8B21878bDECa8bD94507120730a40dCd4F61',
  '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
  '0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c',
  '0xad543f18cFf85c77E140E3E5E3c3392f6Ba9d5CA',
  '0x6De33698e9e9b787e09d3Bd7771ef63557E148bb',
  '0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4',
  '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
  '0x3795C36e7D12A8c252A20C5a7B455f7c57b60283',
  '0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF',
  '0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52',
  '0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E'
]

// hide from overview list
export const OVERVIEW_TOKEN_BLACKLIST = [
  '0x2ec03ab8dc35eedccdbe005a2ef85b9bfae8dba5',
  '0xed80AeFA6D4207c98Bcc0fc56Dfe8885b6097ba6'.toLowerCase(),
  '0x50BbCb51A5E94FDEaaD3d8146BfAbc6eb6674849'.toLowerCase(),
  '0x4dFd9FF90464b8d1e26Fb33df7B8D4f78D912B53'.toLowerCase(),
  '0xdA5a8d16BE24861b6911F93e1197f353DADe6609'.toLowerCase(),
  '0x244f4930add73Ecd17c94D61777ac95979F60303'.toLowerCase(),
  '0x41E6D39744CB4aADC59db4C51E5Ea8C135462638'.toLowerCase(),
  '0x5bb516F2B09776C0Fe6F5c6bAD2a30C5Fe700D54'.toLowerCase(),
  '0xa0A1948B0F07F75E1C8C78A1ACEbf7cd02e359E5'.toLowerCase(),
  '0x559865d3764fD4F32FB14f64947DEd44aE0f39d2'.toLowerCase(),
  '0xfbc33117a901803157dCCA1A8f1763FdAb60D5c0'.toLowerCase()
]

// pair blacklist
export const PAIR_BLACKLIST = ['0x603a384c5b29942701de3a6114fabd6d1aa4034a']

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = ['0xd46ba6d942050d489dbd938a2c909a5d5039a161']
