import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PlaceHolder from '../../assets/placeholder.png'
import CoreLogo from '../../assets/core.png'
import BowLogo from '../../assets/bow_token.png'
import HuntLogo from '../../assets/hunt.png'
import AcoreLogo from '../../assets/acore.png'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <Image {...rest} alt={''} src={PlaceHolder} size={size} />
      </Inline>
    )
  }

  if (address?.toLowerCase() === '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={CoreLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  if (address?.toLowerCase() === '0x1a639e150d2210a4be4a5f0857a9151b241e7ae4') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={BowLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  if (address?.toLowerCase() === '0x962D45C91e2e4f29DdC96C626976ECE600908Ba6'.toLowerCase()) {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={HuntLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  if (address?.toLowerCase() === '0x1c92e17cd1893e8535f48b03fe1207a1b12eaeab'.toLowerCase()) {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={AcoreLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  let path = '';

  if (address?.toLowerCase() === '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720'.toLowerCase()) {
    path = 'https://app.arthswap.org/images/coins/0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720.png';
  } else if(address?.toLowerCase() === '0xeAB3aC417c4d6dF6b143346a46fEe1B847B50296'.toLowerCase()) {
    path = 'https://assets.coingecko.com/coins/images/279/large/ethereum.png';
  } else if(address?.toLowerCase() === '0xad543f18cFf85c77E140E3E5E3c3392f6Ba9d5CA'.toLowerCase()) {
    path = 'https://assets.coingecko.com/coins/images/1/large/bitcoin.png';
  } else if(address?.toLowerCase() === '0x6De33698e9e9b787e09d3Bd7771ef63557E148bb'.toLowerCase()) {
    path = 'https://get.celer.app/cbridge-icons/DAI.png';
  } else if(address?.toLowerCase() === '0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4'.toLowerCase()) {
    path = 'https://arthswapv1-demo-app.s3.ap-northeast-1.amazonaws.com/build/images/coins/0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4.png';
  } else if(address?.toLowerCase() === '0xa4151B2B3e269645181dCcF2D426cE75fcbDeca9'.toLowerCase()) {
    path = 'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png';
  } else if(address?.toLowerCase() === '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1'.toLowerCase()) {
    path = 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png';
  } else if(address?.toLowerCase() === '0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF'.toLowerCase()) {
    path = 'https://get.celer.app/cbridge-icons/MATIC.png';
  } else if(address?.toLowerCase() === '0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52'.toLowerCase()) {
    path = 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png';
  } else if(address?.toLowerCase() === '0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E'.toLowerCase()) {
    path = 'https://pancakeswap.finance/images/tokens/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png';
  } else if(address?.toLowerCase() === '0x000000000E1D682Cc39ABe9B32285fdEa1255374'.toLowerCase()) {
    path = 'https://exchange.archerswap.finance/images/coins/cid.png';
  } else if(address?.toLowerCase() === '0x25100C0083e8E53b1cb264E978522bd477011A0d'.toLowerCase()) {
    path = 'https://exchange.archerswap.finance/images/coins/hobo.png';
  }

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
